<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 我的订单
 * @Date: 2020-12-27 22:08:16
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-02-19 12:09:25
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/myorder.vue
-->
<template>
  <div class="user_content">
    <div v-for="(item,index) in list" :key="index" class="user_tool">
      <div class="title">
        <span class="tool_title">{{ item.orderName }}</span>
        <span class="tool_ftitle">{{ item.orderSubName }}</span>
      </div>
      <van-grid :border="false" :column-num="5">
        <van-grid-item v-for="(items,indexs) in item.orderCategory" :key="indexs" @click="intoorder(items)">
          <img class="tool_icon" :src="items.iconUrl">
          <span class="tool_text">{{ items.title }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <Dowyuetao :second="second" @closepop="isshow" />
  </div>
</template>

<script>
import {
  myOrder
} from '@/services/userApi'
import Dowyuetao from '@/component/dowyuetao'
import Vue from 'vue'
import { Grid, GridItem, Icon, Toast } from 'vant'

Vue.use(Icon).use(Toast).use(Grid).use(GridItem)
export default {
  components: {
    Dowyuetao
  },
  filters: {},
  data() {
    return {
      second: false,
      list: []
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    isshow() {
      this.second = !this.second
    },
    getinfo() {
      myOrder().then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        } else {
          Toast(res.msg)
        }
      })
    },
    intoorder(t) {
      const { orderType } = t
      const sy = t.title
      let url, query
      switch (sy) {
        case '直播订单':
          url = '/liveorder'
          break
        case '限时半价':
          url = '/halfprice'
          break
        case '秒杀订单':
          url = '/seckill'
          break
        case '砍价订单':
          url = '/Bargaining'
          break
        case '拼团订单':
          url = '/Groupwork'
          break
        case '商城订单':
          url = '/Shopping'
          query = { 'state': 0 }
          break
        case '待支付':
          url = '/Shopping'
          query = { 'state': 1 }
          break
        case '待收货':
          url = '/Shopping'
          query = { 'state': 3 }
          break
        case '待评价':
          url = '/Shopping'
          query = { 'state': 4 }
          break
        case '退款售后':
          url = '/Shopping'
          query = { 'state': 5 }
          break
        case '积分商城':
          url = '/integral'
          break
        case '全部订单':
          url = '/retailers'
          query = { 'state': '' }
          break
        case '淘宝':
          url = '/retailers'
          query = { 'state': 7 }
          break
        case '京东':
          url = '/retailers'
          query = { 'state': 1 }
          break
        case '拼多多':
          url = '/retailers'
          query = { 'state': 0 }
          break
        case '唯品会':
          url = '/retailers'
          query = { 'state': 2 }
          break
        case '苏宁':
          url = '/retailers'
          query = { 'state': 3 }
          break
        case '亚马逊':
          url = '/retailers'
          query = { 'state': 6 }
          break
        case '饿了吗':
          url = '/retailers'
          query = { 'state': 12 }
          break
        case '美团外卖':
          url = '/retailers'
          query = { 'state': 5 }
          break
        case '叮当快药':
          url = '/retailers'
          query = { 'state': 11 }
          break
        case '一键加油':
          url = '/comeon'
          query = { 'type': 1, orderType: t.orderType }
          break
        case '视频充值':
          url = '/lifeServiceOrder'
          query = { 'type': '3' }
          break
        case '手机充值':
          url = '/lifeServiceOrder'
          query = { 'type': '2' }
          break
        case '酒店':
          // url = '/hotel'
          // eslint-disable-next-line no-return-assign
          return this.second = !this.second
        // case '飞机票':
        //   url = '/planeticket'
        // break;
        // case '火车票':
        //   url = '/train'
        // break;
        case '电影票':
          url = '/cinematicket'
          break
        case '金融服务':
          url = '/Credit/ApplicationRecord'
          break
        // case '曹操出行':
        //   url = '/Travel'
        // break;
        case '好大夫':
          url = '/doctor'
          // return this.second = !this.second
          // Toast("暂时未开通服务")
          break
      }
      switch (orderType) {
        case 117:
          url = '/universal-studios-order'
      }
      if (!url) {
        return
      }
      this.$router.push({ path: url, query: query })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .user_tool {
    margin: 10px 13px 10px;
    border-radius: 7px;
    background: #FFFFFF;
    padding: 10px;

    .title {
      display: flex;
      align-items: center;

      .tool_title {
        color: #333333;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
      }

      .tool_ftitle {
        color: #999999;
        font-size: 12px;
      }
    }

    .tool_icon {
      width: 43px;
      height: 27px;
      margin-bottom: 7px;
    }

    .tool_text {
      color: #333333;
      font-size: 11px;
    }
  }
}

/deep/ .van-grid-item__content {
  padding: 10px 5px;
}
</style>
