<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 头部注释
 * @Date: 2021-01-05 15:42:57
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-05 17:22:25
 * @FilePath: /yue_quanzhan_h5_new/src/component/dowyuetao/index.vue
-->
<template>
  <div v-if="second" class="modality">
    <div class="mode_box">
      <div class="close" @click="close">
        <van-icon name="cross" color="#fff" size="0.6rem" />
      </div>
      <div class="mode_top">
        <p>为保证您的账户安全</p>
        <p>请前往应用商店下载“悦淘APP”</p>
      </div>
      <div class="tip">
        <img src="@/assets/images/dowtip.png" alt="">
      </div>
      <div class="btn" @click="JumpLink">去下载</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Toast } from 'vant'

Vue.use(Icon).use(Toast)
import { wakeApp } from '@/utils/public.js'

export default {
  props: {
    second: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    JumpLink() {
      wakeApp()
    },
    close() {
      this.$emit('closepop')
    }
  }
}
</script>

<style lang="less" scoped>
.modality {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .mode_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;

    .close {
      position: absolute;
      top: 5%;
      right: 5%;
    }

    .mode_top {
      height: 95px;
      background-image: url('~@/assets/images/ba_mock.png');
      background-size: 100%, 100%;
      background-repeat: no-repeat;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 15px;

      & > p {
        padding-top: 20px;

      }
    }

    .tip {
      width: 276px;
      height: 57px;
      margin: 20px auto;
      background: #fff;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .btn {
      background: #F7263C;
      color: #fff;
      padding: 13px;
      margin: 20px 30px;
      width: 274px;
      border-radius: 6px;
    }
  }
}
</style>
